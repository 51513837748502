import "./scss/main.scss";
// GSAP
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// GSAP Plugins registrieren
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
// app
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
