<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>

import mediaQueryBreakpoints from "./utilities/composition/helpers/mediaQueryBreakpoints";
import deviceDetection from "./utilities/composition/helpers/deviceDetection";

import { useStore } from "vuex";

import {
  ref,
  watchEffect,
  onMounted,
  onBeforeUnmount,
  onUpdated,
  nextTick,
} from "vue";

export default {
  name: "App",
  setup() {
    const { isMobileAny } = deviceDetection();
    // const appDom = document.querySelector("#app");
    const store = useStore();
    const DOMLOADED = ref(false);
    const WINLOAD = ref(false);

    function setScreenState() {
      let wW = window.innerWidth;
      if (wW <= mediaQueryBreakpoints.small) {
        console.log("small");
        store.commit("setScreenState", "small");
        return;
      }
      if (wW < mediaQueryBreakpoints.medium) {
        console.log("medium");
        store.commit("setScreenState", "medium");
        return;
      }
      if (wW <= mediaQueryBreakpoints.large) {
        console.log("large");
        store.commit("setScreenState", "large");
        return;
      }
      if (wW <= mediaQueryBreakpoints.xlarge) {
        console.log("xlarge");
        store.commit("setScreenState", "xlarge");
        return;
      } else {
        console.log("xxlarge");
        store.commit("setScreenState", "xxlarge");
        return;
      }
    }
    onBeforeUnmount(() => {
      window.removeEventListener("resize", setScreenState);
    });

    onMounted(() => {
      setScreenState();
      window.addEventListener("resize", setScreenState);

      if (isMobileAny()) {
        document.body.classList.add("is-mobile");
        store.commit("setIsMobile", true);
      }

      window.addEventListener("load", () => {
        WINLOAD.value = true;
      });
      document.addEventListener("DOMContentLoaded", () => {
        DOMLOADED.value = true;
      });
    });

    onUpdated(() => { });

    nextTick(() => { });

    watchEffect(() => {


    });

    // const lenis = new Lenis(
    //  { duration:2}
    // )


    // function raf(time) {
    //   lenis.raf(time)
    //   requestAnimationFrame(raf)
    // }

    // requestAnimationFrame(raf);
    return {
      DOMLOADED,
      store,
    };
  },
};
</script>

